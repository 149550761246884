import React , {useState, useContext } from 'react';
//import gql from 'graphql-tag';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar'
import { LinkContainer } from "react-router-bootstrap";
import { getAccessToken } from '../AccessToken';
//const activeStyle = { color: "#000000" };
//import { Redirect, Route } from 'react-router-dom';
//import {useQuery} from 'react-apollo';
import { Container, Alert } from 'reactstrap';
import Udslogo from "../img/logo.png"
import { UserConsumer }  from './userContext';

import { useHistory } from "react-router-dom";
//import  Desloguearse2  from "./Desloguearse2";
//import {useMutation, useApolloClient } from 'react-apollo';
//import gql from 'graphql-tag';
import { Redirect } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
//import {useQuery} from 'react-apollo';
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import UserContext from '../componets/userContext';
import { Navigate } from "react-router-dom";

/* Your icon name from database data can now be passed as prop */
const DynamicIcon = ({ name }) => {
    //console.log(name);
    const IconComponent = Icon[name];
    //Icon.FileText
    //if (!IconComponent) { // Return a default one
    //    return <Icon.House style={{ marginRight: '10px'}} />;
    //}
    return <IconComponent style={{ marginRight: '10px'}} />;
};

//<Nav variant="pills" className="mr-auto" onSelect={handleSelect}>
  //const handleSelect = eventKey => alert(`selected ${eventKey}`);

  function Cabecera () {
    const usuario = useContext(UserContext)  
    const tipopersona = usuario.persona.personatipopers[0].TipoPersona.Pk_tipopersona;

    const [logueado] = useState(getAccessToken());
    //const perfilweb = usuario.persona.personatipopers[0].perfilweb;

    const client = useApolloClient();

    const history = useHistory();

    function Desloguearse() {

        const QueryDeslog = gql` mutation {
            logoutUds
        }`;

        const [logout] = useMutation(QueryDeslog, {
            onCompleted: () => {
                 client.clearStore().then(() => {
                    client.resetStore();
                    history.push('/login')  // redirect user to login page
                  });
                //console.log("LOGOUTTTTTTTTTTTTTTTTTTTTTTT");
            },
            onError: () => history.push("/login"),
            });
            //console.log(logout);
            
        return logout;
    }

    const handleLogout = () => {
        const deslo = Desloguearse();
        return deslo;
    };

    

    
    // const QueryMenu = gql`query obtenermenu($perfilweb: Int!) {
    //     allmenus(perfilweb: $perfilweb) {
    //         Pk_menu
    //         Descripcion
    //         iconoReact
    //         detallemenus {
    //             Pk_detallemenu
    //             Descripcion
    //             Link
    //             Reactlink
    //               detalleperfil{
    //               pk_detalleperfil
    //               perfil
    //             }
    //         }
    //     }
    //   }`;
      
    // const { loading, error, data } = useQuery(QueryMenu,
    //     { variables: { 
    //         perfilweb: perfilweb,
    //      }, fetchPolicy: "network-only" },);
        
    //  useEffect(() => {
    //      if(data) {
    //          const datos1 = data.allmenus;
    //          console.log(JSON.stringify(datos1));
    //          //setDatos(datos1)
    //      }
    //  }, [data]); 


    //      if (loading) 
    //         return <Alert color="info">
    //             Cargando...
    //         </Alert>;
      
    //     if (error) {
    //         console.log(error.message)
    //         return (
    //           <Alert color="danger">
    //             {error.message}
    //           </Alert>
    //         )
    //     } 


    const token= getAccessToken();
    //console.log(token);
    if (!token) {
        console.log("No logueado");
        return <Redirect to='/login' />;
    }

        
    // const ObtenerMenus =  async () => {
    //     const Querygql = {
    //         query: `query {
    //         allmenus {
    //             Pk_menu
    //             Descripcion
    //             detallemenu{
    //                 Pk_detallemenu
    //                 Descripcion
    //                 Link
    //               }
    //           }
    //       }`};
    //       const response = await  fetch('http://localhost:5000/graphql', {
    //         method: 'POST',
    //         body: JSON.stringify(Querygql),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //     if (response.ok) { // if HTTP-status is 200-299
    //       // get the response body (the method explained below)
    //       const body = await response.json();
    //       //console.log("TIK" + JSON.stringify(body));
    //       const datos = body.data.allmenus;
    //       //console.log("TIK" + JSON.stringify(datos));
    //       setDatos(datos)
       
    //     } else {
    //       alert("HTTP-Error: " + response.status);
    //     }
    // }

    /* const ObtenerUsuario = () => {
        //onst data = ObtenerDatosAL();
        const QueryAnioLectivo = gql`query {
          usuarioLogueado{
            pk_usuario
            Persona
            User
          }
        }`;
        const { loading, error, data } = useQuery(QueryAnioLectivo);
        
        if (loading) return 'Loading...';
        
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
        }
        const usuario = data.usuarioLogueado;
        //console.log(JSON.stringify(usuario));

        return (
            <div>
                USUARIO: {usuario.User}
            </div>
        )
      } */
    const ObtenerMenuNotas = () => {
        //console.log("LOGUEADO " + logueado);
        if (logueado) {
            if (tipopersona==1) {
                return (
                    <Nav variant="pills" className="mr-auto" navbar>
                        <Nav.Item>
                            <LinkContainer to="./notas">
                                <Nav.Link>NOTAS</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                     </Nav>
                )
            }
            else {
                return (
                    <Nav variant="pills" className="mr-auto" navbar>
                        
                        <Nav.Item>
                            <LinkContainer to="./homologaciones">
                                <Nav.Link>HOMOLOGACIÃ“N</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="./inscripciones">
                                <Nav.Link>INSCRIPCIONES</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="./pagos">
                                <Nav.Link>PAGOS</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="./titulos">
                                <Nav.Link>TÃTULOS</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    </Nav>
                )
            }
        }
        else {
            return <Redirect to='/login'  />
        }
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" >
            <Container>
                <Navbar.Brand >
                    <img
                        src={Udslogo}
                        width="40"
                        height="40"
                        className="d-inline-block align-top"
                        alt="UDS"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                
                <Navbar.Collapse id="responsive-navbar-nav">
                    
        
                    {ObtenerMenuNotas()}
                  
                    { /*<NavDropdown title="SESIÃ“N" id="nav-dropdown">
                            <NavDropdown.Item eventKey="4.1">
                            <Alert color="success">
                                {ObtenerUsuario()}
                                
                            </Alert>

                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item eventKey="4.2">Cerrar sesiÃ³n</NavDropdown.Item>
                    </NavDropdown>*/
                    }
            
                    <Nav variant="pills" className="ms-auto">
                        
                    { /* <Nav.Item>
                            <LinkContainer to="./movimiento">
                                <Nav.Link>MOVIMIENTO</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>

                        <Nav.Item>
                            <LinkContainer to="./matriculaciones">
                                <Nav.Link>MATRICULACIONES</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>*/
                    }

                        <Nav.Item>
                            <LinkContainer to="#">
                                <Nav.Link>
                                    <UserConsumer>   
                                        { user => { 
                                            return  <div>USUARIO: {user.User}</div>
                                            }
                                        }
                                    </UserConsumer>    
                                </Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                         
                        <Nav.Item>
                            <LinkContainer to="./login">
                                <Nav.Link>LOGIN</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link onClick={Desloguearse()}>LOGOUT</Nav.Link>
                        </Nav.Item> 
                       

                        {/* <Nav.Item>
                            <LinkContainer to="./logout">
                                <Nav.Link>LOGOUT</Nav.Link>
                            </LinkContainer>
                        </Nav.Item> */}
                        {/*  <Nav.Item>
                            <LinkContainer to="./usuarios">
                                <Nav.Link>USUARIOS</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item>
                            <LinkContainer to="./usuarioEspecifico">
                                <Nav.Link>USUARIO ESP</Nav.Link>
                            </LinkContainer>
                        </Nav.Item> */}
                    
                        
                                { /*
                                <NavLink key='Home' className="nav-link" to="./">Home</NavLink> 
                                <NavLink className="nav-link" to="./ticket">Ticket</NavLink>
                                

                                {//TRAE EL MENU DE LA BD
                                datos.map(Menu => (
                                    //<NavDropdown key={Menu.Pk_menu} title={Menu.Descripcion} id="basic-nav-dropdown" > 
                                    <NavDropdown  key={Menu.Pk_menu} title={<span>
                                        <DynamicIcon name={Menu.icono} />
                                        {Menu.Descripcion}
                                        </span>} id="basic-nav-dropdown" >              
                                        {Menu.detallemenu.map((detallemenu) => 
                                        
                                            <LinkContainer key={detallemenu.Pk_detallemenu} to={detallemenu.Reactlink}> 
                                                <NavDropdown.Item > 
                                                    {detallemenu.Descripcion}
                                                </NavDropdown.Item>
                                            </LinkContainer>


                                            //<NavDropdown.Item style={{  }}  key={detallemenu.pk_detallemenu}>
                                               // <NavLink style={{ color: 'black', textDecoration: 'inherit' }} exact to={detallemenu.Link}>
                                                //    {detallemenu.Descripcion}
                                                //</NavLink>
                                            //</NavDropdown.Item>

                                            //<NavDropdown.Item key={detallemenu.pk_detallemenu} href="./ticket">{detallemenu.Descripcion}</NavDropdown.Item>
                                        )}
                                    </NavDropdown> 
                                ))
                                }
                                */}
                        </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default Cabecera;